const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api';
} else {
    baseUrl = '/api';
}

export default {
    auth: {
        login: baseUrl + '/auth/login/',
        updatePassword: baseUrl + '/auth/set-password/',
        logout: baseUrl + '/auth/logout/',
        status: baseUrl + '/auth/status/'
    },
    vueSelect: {
        speciesOptions: baseUrl + '/master-data/select/species/',
        breedOptions: baseUrl + '/master-data/select/breed/'
    },
    admin: {
        bulls: {
            add: baseUrl + '/bull/add/',
            list: baseUrl + '/bull/list/',
            edit: baseUrl + '/bull/edit/',
            details: baseUrl + '/bull/details/',
            delete: baseUrl + '/bull/delete/'
        },
        dashboard: baseUrl + '/dashboard/count/',
        contact: {
            add: baseUrl + '/communicate/add/',
            list: baseUrl + '/communicate/list/',
            edit: baseUrl + '/communicate/edit/',
            delete: baseUrl + '/communicate/delete/'
        },
        facility: {
            add: baseUrl + '/facility/add/',
            list: baseUrl + '/facility/list/',
            edit: baseUrl + '/facility/edit/',
            delete: baseUrl + '/facility/delete/'
        },
        awards: {
            add: baseUrl + '/awards/add/',
            list: baseUrl + '/awards/list/',
            edit: baseUrl + '/awards/edit/',
            delete: baseUrl + '/awards/delete/'
        },
        species: {
            add: baseUrl + '/master-data/species/add/',
            list: baseUrl + '/master-data/species/list/',
            edit: baseUrl + '/master-data/species/edit/',
            delete: baseUrl + '/master-data/species/delete/'
        },
        breed: {
            add: baseUrl + '/master-data/breed/add/',
            list: baseUrl + '/master-data/breed/list/',
            edit: baseUrl + '/master-data/breed/edit/',
            delete: baseUrl + '/master-data/breed/delete/'
        },
        banner: {
            add: baseUrl + '/banner/add/',
            list: baseUrl + '/banner/list/',
            edit: baseUrl + '/banner/edit/',
            details: baseUrl + '/banner/details/'
        },
        policy: {
            add: baseUrl + '/policy/add/',
            list: baseUrl + '/policy/list/',
            edit: baseUrl + '/policy/edit/',
            details: baseUrl + '/policy/details/'
        }
    }
};
