import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */ '../layouts/MainLayout');
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home');
const About = () => import(/* webpackChunkName: "home" */ '../views/About');
const Logout = () => import(/* webpackChunkName: "home" */ '../views/auth/Logout');
const UpdatePassword = () => import(/* webpackChunkName: "home" */ '../views/auth/UpdatePassword');
const Dashboard = () => import(/* webpackChunkName: "home" */ '../views/Dashboard');

export default {
    path: '/admin/',
    name: 'DashboardLayout',
    redirect: '/dashboard/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/',
            redirect: '/dashboard/',
            name: 'Home',
            component: Home
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '/update-password/',
            name: 'UpdatePassword',
            component: UpdatePassword
        },
        {
            path: '/logout/',
            name: 'Logout',
            component: Logout
        },
        {
            path: '/dashboard/',
            name: 'Dashboard',
            component: Dashboard
        },
        // Master Data Section
        {
            path: '/breeds/',
            name: 'Breeds',
            component: () => import('../views/master-data/BreedsListingComponent')
        },
        {
            path: '/semen-storage/',
            name: 'SemenStorage',
            component: () => import('../views/master-data/SemenStorageListing')
        },
        {
            path: '/species/',
            name: 'SpeciesList',
            component: () =>
                import('../views/master-data/SpeciesListingComponent')
        },
        // Other Components
        {
            path: '/bulls/',
            name: 'Bulls',
            component: () =>
                import('../views/bulls/BullsListingComponent')
        },
        {
            path: '/bull/:id/details/',
            name: 'BullInfo',
            component: () =>
                import('../views/bulls/BullDetails')
        },
        {
            path: '/facilities/',
            name: 'Facilities',
            component: () =>
                import('../views/facilities/FacilityListingComponent')
        },
        {
            path: '/awards/',
            name: 'Awards',
            component: () =>
                import('../views/awards/AwardsListingComponent')
        },
        // Setting Components
        {
            path: '/contact-us/',
            name: 'ContactUs',
            component: () =>
                import('../views/settings/contact-us/ContactUsListingComponent')
        },
        {
            path: '/settings/banners/',
            name: 'BannerSettings',
            component: () =>
                import('../views/settings/banners/ViewPage')
        },
        {
            path: '/settings/terms-and-privacy/',
            name: 'TermsSettings',
            component: () =>
                import('../views/settings/terms-and-privacy/ViewPage')
        }
    ]
};
